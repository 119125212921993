import Home from "pages/home";
import Careers from "pages/careers";
import Expertise from "pages/expertise";
import Startups from "pages/startups";
import { Route, Routes } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import withScreenHeaders from "hoc/withScreenHeaders";
import IdentityDetails from "pages/identity_details";
import "./app.css";

function App() {
  return (
    <>
      <Routes>
        <Route
          element={withScreenHeaders(Careers)()}
          path={NavigationRoutes.careers}
        />
        <Route
          element={withScreenHeaders(Home)()}
          path={NavigationRoutes.home}
        />
        <Route
          element={withScreenHeaders(IdentityDetails)()}
          path={NavigationRoutes.identity}
        />
        <Route element={<Expertise />} path={NavigationRoutes.expertise} />
        <Route
          element={withScreenHeaders(Startups)()}
          path={NavigationRoutes.startups}
        />
      </Routes>
    </>
  );
}

export default App;
