import { FC } from "react";
import { Box } from "@mui/material";

type Props = {
  spacingY?: number;
  reverse?: boolean;
};

const Spacing: FC<Props> = ({ spacingY }) => {
  return <Box sx={{ marginBlock: spacingY ? spacingY : 40 }} />;
};

export default Spacing;
