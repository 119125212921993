import { FC, useState } from "react";
import { Box, Paper, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import "./hover.css";

type Props = {
  icons: { white: string; defaultIc: string };
  title: string;
  desc: string;
  sectionId?: string;
};

const ExpertiseCard: FC<Props> = ({ icons, title, sectionId, desc }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const navigate = useNavigate();

  const theme = useTheme();

  const handleClick = () => {
    navigate(`${NavigationRoutes.expertise}`, {
      state: {
        sectionId,
      },
    });
  };

  return (
    <Paper
      onClick={() => handleClick()}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        paddingBlock: 2,
        paddingInline: 1,
        height: { xs: 150, md: 250 },
        boxShadow: 2,
        borderRadius: 1.5,
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        transition: theme.transitions.create(
          ["background", "background-color"],
          {
            duration: theme.transitions.duration.complex,
          }
        ),
        "&:hover": {
          backgroundColor: "#0463D5",
          color: "white",
        },
      }}
    >
      {!isHovered && (
        <Stack direction="row" justifyContent="flex-end">
          <Box
            component="img"
            sx={{ width: { xs: 40, md: 70 } }}
            src={icons.defaultIc}
          />
        </Stack>
      )}
      {isHovered && (
        <Stack direction="row" justifyContent="flex-end">
          <Box
            component="img"
            sx={{ width: { xs: 40, md: 70 } }}
            src={icons.white}
          />
        </Stack>
      )}
      <Stack
        direction="column"
        width="100%"
        rowGap={{ md: 1, xs: 0.6 }}
        justifyContent="flex-end"
      >
        <>
          <Typography
            sx={{
              width: "100%",
              fontFamily: "Poppins, Roboto",
              fontSize: { lg: 23, sm: 20, md: 23, xs: 12 },
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: { lg: 14, sm: 13, md: 14, xs: 10 },
              width: "100%",
              opacity: 0.6,
              fontFamily: "Poppins, Roboto",
            }}
          >
            {desc}
          </Typography>
        </>
      </Stack>
    </Paper>
  );
};

export default ExpertiseCard;
