import * as yup from "yup";

export const enum CONTACT_FORM_NAMES {
  name = "name",
  email = "email",
  phone = "phone",
  category = "category",
  inquiry = "inquiry",
}

const validationSchema: yup.Schema = yup.object({
  [CONTACT_FORM_NAMES.name]: yup.string(),
  [CONTACT_FORM_NAMES.category]: yup.string(),
  [CONTACT_FORM_NAMES.email]: yup.string(),
  [CONTACT_FORM_NAMES.phone]: yup.number().max(13),
  [CONTACT_FORM_NAMES.inquiry]: yup.string(),
});

const initialValues: yup.InferType<typeof validationSchema> = {
  [CONTACT_FORM_NAMES.name]: "",
  [CONTACT_FORM_NAMES.category]: "",
  [CONTACT_FORM_NAMES.email]: "",
  [CONTACT_FORM_NAMES.phone]: "",
  [CONTACT_FORM_NAMES.inquiry]: "",
};

export { validationSchema, initialValues };
