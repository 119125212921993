import { FC, PropsWithChildren } from "react";
import { Typography } from "@mui/material";
import styles from "./styles";

const ErrorMessage: FC<PropsWithChildren<NonNullable<unknown>>> = ({
  children,
}) => {
  return (
    <Typography data-testid="error-message" sx={styles.container}>
      {children}
    </Typography>
  );
};

export default ErrorMessage;
