import rocket from "assets/rocket.svg";
import web from "assets/web.svg";
import hardware from "assets/hardware.svg";
import white_hardware from "assets/white_hardware.svg";
import search from "assets/search.svg";
import ui_ux from "assets/ui_ux.svg";
import support from "assets/support.svg";
import white_support from "assets/white_support.svg";
import white_ui_ux from "assets/white_ui_ux.svg";
import staff from "assets/staff.svg";
import white_staff from "assets/white_staff.svg";
import white_search from "assets/white_search.svg";
import automation from "assets/web.svg";
import white_automation from "assets/white_automation.svg";
import white_web from "assets/white_web.svg";
import white_rocket from "assets/white_rocket.svg";

const data = [
  {
    id: 1,
    img: rocket,
    white: white_rocket,
    title: "Software Development",
    desc: "Building bespoke software to meet your unique needs",
    sectionId: "#software",
  },
  {
    id: 2,
    img: web,
    white: white_web,
    title: "Web & Mobile Development",
    desc: "Turning ideas to innovative solutions for your digital success",
    sectionId: "#web",
  },
  {
    id: 3,
    img: automation,
    white: white_automation,
    sectionId: "#automation",
    title: "Intelligent automation",
    desc: "Enhancing efficiency and productivity through AI-powered intelligent automation solutions.",
  },
  {
    id: 4,
    img: search,
    white: white_search,
    title: "Data Analytics",
    sectionId: "#data",
    desc: "Transforming data into actionable insights for informed decisions and business growth.",
  },
  {
    id: 5,
    img: staff,
    white: white_staff,
    title: "Staff Augmentation",
    sectionId: "#staff",
    desc: "Scalable staffing solutions, providing skilled professionals to elevate your company's success.",
  },
  {
    id: 6,
    img: ui_ux,
    white: white_ui_ux,
    title: "UX / UI Design",
    sectionId: "#ui_ux",
    desc: "Crafting user-centric designs for digital interfaces that captivate and engage..",
  },
  {
    id: 8,
    img: hardware,
    white: white_hardware,
    title: "General technical hardware ",
    sectionId: "#hardware",
    desc: "Our team of experts is experienced in sourcing high-quality and reliable hardware to ensure optimal performance for our clients.",
  },
  {
    id: 7,
    img: support,
    white: white_support,
    title: "Support & Maintenance ",
    sectionId: "#support",
    desc: "Proactive support & maintenance to ensure optimal performance and uninterrupted operations.",
  },
];

export default data;
