import { FC } from "react";
import { Box } from "@mui/material";
import LeftSection from "./left_section";
import RightSection from "./right_section";
import styles from "./styles";
import Spacing from "components/spacing";

const WhyUs: FC = () => {
  return (
    <Box sx={styles.wrapper}>
      <LeftSection />
      <Spacing spacingY={2} />
      <RightSection />
    </Box>
  );
};

export default WhyUs;
