import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Identity, data } from "./data";

type State = {
  data: Array<Identity>;
  activeItem: Identity;
};

const initialState: State = {
  data,
  activeItem: data[0],
};

const IdentitySlice = createSlice({
  name: "identity",
  initialState,
  reducers: {
    setActiveItem: (state: State, { payload }: PayloadAction<number>) => {
      const newItem = data[payload];
      state.activeItem.desc = newItem.desc;
      state.activeItem.title = newItem.title;
      state.activeItem.buttonLabel = newItem.buttonLabel;
      return state;
    },
  },
});

export default IdentitySlice.reducer;
export const IdentitySliceActions = IdentitySlice.actions;
