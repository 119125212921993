export type Reason = {
  id: string | number;
  title: string;
  subTitle: string;
  desc: string;
};

export const data: Array<Reason> = [
  {
    id: 1,
    title: "Uncompromising",
    subTitle: "Excellence",
    desc: "At Crowd Digital, we uphold stringent quality standards to ensure precision and excellence throughout every phase of our development process. Our unwavering commitment to quality is integral to our ",
  },
  {
    id: 2,
    title: "Hiring Top 3% ",
    subTitle: "Talents",
    desc: "Crowd Digital excels in curating the top 3% of industry talent. Our rigorous selection process ensures we bring onboard professionals with exceptional skills and experience. This ",
  },
  {
    id: 3,
    subTitle: "Estimates Match",
    title: "100%",
    desc: "We take immense pride in our precise project timeline and cost estimations. Our meticulous planning, coupled with extensive experience, consistently results in a remarkable 100% accuracy rate in our estimates. This track record underlines our commitment to delivering projects on time and within budget.",
  },
];
