import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    whiteSpace: "nowrap",
    paddingInline: 2.5,
    paddingBlock: 0.5,
    borderRadius: 2,
  },
};

export default styles;