import { FC } from "react";
import { Box, Grid } from "@mui/material";
import styles from "./styles";
import InputField from "components/input_field";
import { Formik } from "formik";
import Button from "components/custom_button";
import { CONTACT_FORM_NAMES, initialValues, validationSchema } from "./form";

const ContactSection: FC = () => {
  const handleSubmitForm = () => {};

  return (
    <Box id="contact_form" sx={styles.container}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
      >
        <>
          <Grid
            container
            width="100%"
            justifyContent={{ lg: "center" }}
            rowGap={1.5}
            columnGap={1.5}
          >
            <Grid item xs={12} md={5.8} lg={5}>
              <InputField
                sx={styles.inputStyle}
                placeholder="Name"
                name={CONTACT_FORM_NAMES.name}
              />
            </Grid>
            <Grid item xs={12} md={5.8} lg={5}>
              <InputField
                sx={styles.inputStyle}
                placeholder="Category"
                name={CONTACT_FORM_NAMES.category}
              />
            </Grid>
            <Grid item xs={12} md={5.8} lg={5}>
              <InputField
                sx={styles.inputStyle}
                placeholder="Email Address"
                name={CONTACT_FORM_NAMES.email}
              />
            </Grid>
            <Grid item xs={12} md={5.8} lg={5}>
              <InputField
                sx={styles.inputStyle}
                placeholder="Phone Number"
                name={CONTACT_FORM_NAMES.phone}
              />
            </Grid>
            <Grid item xs={12} md={11.74} lg={10.15}>
              <InputField
                sx={{
                  ...styles.inputStyle,
                  ...{
                    marginBlock: 1,
                    width: { xs: "100%" },
                  },
                }}
                multiline
                rows={8}
                placeholder="How can we help you?"
                name={CONTACT_FORM_NAMES.inquiry}
              />
            </Grid>
          </Grid>
          <Box sx={styles.actionButtons}>
            <Button
              extraStyles={{ color: "black", borderColor: "#0364D9" }}
              label="Attach"
              variant="outlined"
            />
            <Button
              extraStyles={{ color: "black", borderColor: "#0364D9" }}
              label="Send"
              variant="outlined"
            />
          </Box>
        </>
      </Formik>
    </Box>
  );
};

export default ContactSection;
