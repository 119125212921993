import { FC, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import MediaSection from "components/media_section";
import styles from "./styles";
import InquiryForm from "components/inquiry_form";
import { useLocation } from "react-router-dom";
import automation from "assets/automation.png";
import data_analytics from "assets/data_analytics.png";
import ui_ux from "assets/ui_ux.png";
import staff from "assets/staff.png";
import support from "assets/support.png";
import hardware from "assets/hardware.png";
import software_dev from "assets/software_dev.png";
import webDev from "assets/web_dev.png";
import AppBar from "components/app_bar";
import Footersection from "components/footer";

const Expertise: FC = () => {
  const { state } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (state && state?.sectionId) {
        const element = document.getElementById(state.sectionId);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }, 1000);
  }, [state]);

  return (
    <>
      <AppBar />
      <Box sx={styles.container}>
        <Typography sx={styles.header}>
          Our <span style={{ color: "#0364D9" }}>Expertise</span>
        </Typography>
        <Stack
          direction="column"
          alignItems={{ xs: "center" }}
          marginBottom={{ lg: 20 }}
          rowGap={20}
        >
          <MediaSection
            sectionId="#software"
            imageSrc={software_dev}
            reverse
            title="Software Development"
            desc="At Crowd Digital, we offer top-notch custom software development services tailored to meet your specific business needs. Our team of experienced developers and engineers is dedicated to delivering high-quality software solutions that drive efficiency, productivity, and innovation for your organization."
          />
          <MediaSection
            sectionId="#web"
            imageSrc={webDev}
            title="Web & Mobile Development"
            desc="We specialize in creating stunning websites and mobile applications that captivate users and drive business growth. Our expert team leverages the latest technologies to ensure your digital presence stands out. From responsive websites to engaging mobile apps, we craft tailored solutions that exceed your expectations."
          />
          <MediaSection
            sectionId="#automation"
            reverse
            imageSrc={automation}
            title="Inteligent automation"
            desc="Our intelligent automation solutions streamline your business processes by combining AI and automation technologies. We offer efficient, data-driven, and cost-effective automation to boost productivity and reduce operational complexities."
          />
          <MediaSection
            sectionId="#data"
            imageSrc={data_analytics}
            title="Data Analytics"
            desc="Our data analytics services provide actionable insights by harnessing the power of data. We leverage advanced analytics tools and techniques to help you make informed decisions, enhance efficiency, and uncover valuable trends in your data."
          />
          <MediaSection
            sectionId="#staff"
            reverse
            imageSrc={staff}
            title="Staff Augmentation"
            desc="Skilled professionals at your fingertips. Gain access to industry-specific skills and experience, saving on recruitment and training costs. We offer flexible staffing solutions for short-term or long-term needs, ensuring you have the right expertise when and where you need it."
          />
          <MediaSection
            sectionId="#ui_ux"
            reverse
            imageSrc={ui_ux}
            title="UX / UI Design"
            desc="We specialize in creating stunning websites and mobile applications that captivate users and drive business growth. Our expert team leverages the latest technologies to ensure your digital presence stands out. From responsive websites to engaging mobile apps, we craft tailored solutions that exceed your expectations."
          />
          <MediaSection
            sectionId="#support"
            imageSrc={support}
            title="Support & Maintenance"
            desc="We ensure the uninterrupted performance and longevity of your digital assets. We provide ongoing assistance and proactive upkeep to keep your systems, websites, and applications running smoothly. Get in touch to safeguard your technology investments and stay ahead of potential challenges."
          />
          <MediaSection
            sectionId="#hardware"
            reverse
            imageSrc={hardware}
            title="General Technical Hardware"
            desc="we are proud to offer exceptional hardware solutions for companies seeking reliable and high-quality equipment. With our expertise in sourcing top-notch hardware, we ensure optimal performance and seamless integration within your business infrastructure. "
          />
        </Stack>
      </Box>
      <InquiryForm />
      <Footersection />
    </>
  );
};

export default Expertise;
