import { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import styles from "./styles";
import ExpertiseCard from "components/expertise_card";
import data from "./data";

const ExpertiseSection: FC = () => {
  return (
    <Box
      sx={{
        paddingBlock: 10,
        width: "100%",
        position: "relative",
        background:
          "linear-gradient(180deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%)",
      }}
    >
      <Typography sx={styles.header}>Our Expertise</Typography>
      <Grid
        container
        justifyContent="center"
        columnGap={2}
        rowGap={{ lg: 5, xs: 2.5 }}
        sx={styles.container}
      >
        {data.map((entry) => (
          <Grid key={entry.id} item xs={5.6} sm={5.7} lg={2.7} xl={2.6}>
            <ExpertiseCard
              sectionId={entry.sectionId}
              icons={{ defaultIc: entry.img, white: entry.white }}
              title={entry.title}
              desc={entry.desc}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ExpertiseSection;