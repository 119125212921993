import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import theme from "theme";
import styles from "./styles";
import aws from "assets/aws.png";
import google from "assets/google.png";
import oracle from "assets/oracle.png";
import microsoft from "assets/microsoft.png";
import slack from "assets/slack.png";

const SwiperView = () => {
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Swiper
      style={{
        height: 150,
      }}
      slidesPerView={upMd ? 4 : 3}
      spaceBetween={20}
      pagination={false}
      loop
      modules={[Autoplay]}
      speed={2000}
      autoplay={{
        delay: 0,
        pauseOnMouseEnter: true,
      }}
    >
      <SwiperSlide style={{ display: "flex", alignItems: "center" }}>
        <Box
          component="img"
          sx={{ width: { xs: 80, lg: 120 }, height: 30, objectFit: "contain" }}
          src={aws}
        />
      </SwiperSlide>
      <SwiperSlide style={{ display: "flex", alignItems: "center" }}>
        <Box component="img" sx={{ width: { xs: 80, lg: 120 } }} src={google} />
      </SwiperSlide>
      <SwiperSlide style={{ display: "flex", alignItems: "center" }}>
        <Box component="img" sx={{ width: { xs: 80, lg: 120 } }} src={oracle} />
      </SwiperSlide>
      <SwiperSlide style={{ display: "flex", alignItems: "center" }}>
        <Box
          component="img"
          sx={{ width: { xs: 80, lg: 120 } }}
          src={microsoft}
        />
      </SwiperSlide>
      <SwiperSlide style={{ display: "flex", alignItems: "center" }}>
        <Box component="img" sx={{ width: { xs: 80, lg: 120 } }} src={slack} />
      </SwiperSlide>
    </Swiper>
  );
};

const Partners: FC = () => {
  return (
    <Box sx={{ marginTop: 5 }}>
      <Typography sx={styles.header}>Our Partners & Recognitions</Typography>
      <SwiperView />
    </Box>
  );
};

export default Partners;
