import { FC } from "react";
import { Box } from "@mui/material";
import IconCard from "components/icon_card";
import { useAppSelector } from "store/hooks";
import { IconMapper } from "../icon_mapper";

const RightSection: FC = () => {
  const reason = useAppSelector((state) => state.whyUs.activeItem);

  return (
    <Box sx={{ width: "100%" }}>
      <IconCard
        title={reason.title}
        desc={reason.desc}
        subTitle={reason.subTitle}
        icon={(IconMapper as any)[reason.id]}
        height={310}
      />
    </Box>
  );
};

export default RightSection;
