import { configureStore } from "@reduxjs/toolkit";
import rootApi from "./api/index";
import globalReducer from "./global_slice/index";
import WhyUsSlice from "./whiy_us_slice";
import IdentitySlice from "./identity_slice/index";

const store = configureStore({
  reducer: {
    [rootApi.reducerPath]: rootApi.reducer,
    global: globalReducer,
    whyUs: WhyUsSlice,
    identity: IdentitySlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type DispatchType = typeof store.dispatch;
export default store;
