import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "100%",
  },
  header: {
    fontSize: { md: 38, xs: 30 },
    mb: 5,
    fontFamily: "Poppins, Roboto",
    textAlign: "center",
  },
};

export default styles;