import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Local } from "types/local";

enum LanguageDirection {
  rightToLeft = "rtl",
  leftToRight = "ltr",
}

type Language = {
  prefix: Local;
  direction: LanguageDirection;
};

type State = {
  lang: Language;
  darkTheme?: boolean;
  appBarState: boolean;
};

const initialState: State = {
  lang: {
    prefix: Local.English,
    direction: LanguageDirection.leftToRight,
  },
  darkTheme: false,
  appBarState: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    changeLanguage: (state: State, { payload }: PayloadAction<Local>) => {
      state.lang.prefix = payload;
      payload === Local.Arabic
        ? (state.lang.direction = LanguageDirection.rightToLeft)
        : (state.lang.direction = LanguageDirection.leftToRight);
      return state;
    },
    changeTheme: (state: State) => {
      !state.darkTheme;
      return state;
    },
    toggleAppBar: (state: State) => {
      state.appBarState = !state.appBarState;
      return state;
    },
  },
});

export default globalSlice.reducer;
export const globalSliceActions = globalSlice.actions;
