import { FC } from "react";
import { Grid } from "@mui/material";
import IconCard from "components/icon_card";
import { useAppDispatch } from "store/hooks";
import { WhyUsSliceActions } from "store/whiy_us_slice";
import { IconMapper } from "../icon_mapper";

const LeftSection: FC = () => {
  const dispatch = useAppDispatch();

  const handleActiveItem = (index: number) => {
    dispatch(WhyUsSliceActions.setActiveItem(index));
  };

  return (
    <Grid container gap={2} columns={7}>
      <Grid item xs={12} md>
        <IconCard
          onClick={() => handleActiveItem(0)}
          title="Uncompromising"
          desc="Excellence"
          icon={IconMapper[1]}
          height={130}
        />
      </Grid>
      <Grid item xs={12} md>
        <IconCard
          onClick={() => handleActiveItem(1)}
          title="Hiring Top 3% "
          desc="Talents"
          icon={IconMapper[2]}
          height={130}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <IconCard
          onClick={() => handleActiveItem(2)}
          title="100% "
          desc="Estimates Match"
          icon={IconMapper[3]}
          height={130}
        />
      </Grid>
    </Grid>
  );
};

export default LeftSection;
