import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginBottom: 5,
    paddingBlock: 10,
    background:
      "linear-gradient(180deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%)",
  },
  seperator: { backgroundColor: "black", width: 1.2, opacity: 0.3, height: 70 },
  mobilePaperWrapper: {
    display: "flex",
    borderRadius: 5,
    marginBlock: 3,
    padding: 1,
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    marginX: "auto",
  },
  paperWrapper: {
    width: "fit-content",
    borderRadius: 10,
    boxShadow: 3,
    display: "flex",
    flexDirection: {
      xs: "row",
    },
    paddingInline: 5,
    paddingBlock: 2,
    justifyContent: "center",
    alignItems: "center",
    marginX: "auto",
    flexWrap: "wrap",
    rowGap: {
      xs: 5,
      md: 0,
    },
    columnGap: {
      xs: 0,
      md: 2,
      lg: 4,
    },
  },
};

export default styles;