import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: {
      xs: "100%",
      xl: "70%",
    },
    marginX: { xl: "auto" },
  },
  inputStyle: {
    backgroundColor: "#B4B4B426",
    borderRadius: "10px",
    width: {
      xs: "100%",
    },
  },
  header: {
    fontSize: { xs: 50, xl: 60 },
    marginInlineStart: {
      lg: 15,
    },
    marginBlock: 3,
  },
  actionButtons: {
    mt: 1.5,
    marginBottom: 5,
    width: {
      xs: "100%",
      lg: "85%",
    },
    marginX: { lg: "auto" },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

export default styles;