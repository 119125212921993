import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    height: { xs: 700, lg: "100dvh" },
    paddingTop: { xs: 10, md: 0, lg: 20 },
  },
  header: {
    textAlign: { xs: "center", md: "start" },
    fontFamily: "Poppins, Roboto",
    fontWeight: "300",
    fontSize: {
      xs: 55,
      lg: 70,
    },
    lineHeight: 1,
  },
  img: {
    width: { xs: 360, sm: 500, lg: 550 },
    aspectRatio: "4 / 3",
    paddingInlineStart: { xs: 3, md: 0 },
    marginTop: { xs: 5, md: 0 },
    zIndex: 100,
    borderRadius: 3,
    marginX: { xs: "auto", md: 0 },
  },
  subHeader: {
    marginTop: { xs: 6, md: 3 },
    fontFamily: "Poppins, Roboto",
    fontSize: { xs: 14, lg: 25 },
    width: { xs: "80%" },
    marginX: { xs: "auto", md: 0 },
    textAlign: { xs: "center", md: "start" },
  },
};

export default styles;
