export type Identity = {
  title: string;
  desc: string;
  buttonLabel: string;
};

export const data: Array<Identity> = [
  {
    title: "We are Developers, investors and advisors",
    buttonLabel: "Learn More",
    desc: "We are dedicated to empowering startup founders, providing valuable advice and mentorship to startups to ensure their success and growth. Additionally, we actively invest in early-stage products, recognizing their market potential and value.",
  },
  {
    title: "We are hiring",
    buttonLabel: "Learn More",
    desc: "We are passionate about supporting startup founders, and our expertise extends beyond software development. We provide valuable advice and guidance to a16z startups, ensuring their success and growth. Additionally, we ",
  },
];
