import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  input: {
    width: "100%",
    backgroundColor: "white",
  },
};

export default styles;
