import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  cursor: {
    cursor: "pointer",
  },
  container: {
    maxWidth: "100%",
    paddingY: 2,
    paddingX: 4,
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    justifyContent: "space-between",
    alignItems: "center",
    rowGap: {
      xs: 5,
      md: 0,
    },
    columnGap: {
      xs: 0,
      md: 5,
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  menu: {
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    justifyContent: "space-between",
    alignItems: "center",
    rowGap: {
      xs: 2,
      md: 0,
    },
    columnGap: {
      xs: 0,
      md: 4,
    },
  },
};

export default styles;
