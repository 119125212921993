import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    justifyContent: "flex-start",
    paddingInline: 5,
  },
};

export default styles;
