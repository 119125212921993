import { FC, PropsWithChildren } from "react";
import {
  Box,
  Grow,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Button from "components/custom_button";

type Props = {
  imageSrc: string;
  title: string;
  desc: string;
  buttonLabel?: string;
  onClick?: () => void;
};

const IdentityItem: FC<PropsWithChildren<Props>> = ({
  imageSrc,
  title,
  desc,
  onClick,
  buttonLabel,
}) => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Grow in timeout={1000}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "center" }}
        justifyContent={{ md: "center" }}
        columnGap={{ md: 5 }}
        sx={{ width: { xs: "80%" }, zIndex: 100, marginX: { xs: "auto" } }}
      >
        {!upMd && (
          <Typography
            sx={{
              fontFamily: "Poppins, Roboto",
              fontSize: { xs: 30, md: 35, lg: 30 },
              width: { md: 200, lg: 300 },
              marginTop: { xs: 2 },
              lineHeight: 1.3,
              textAlign: { xs: "center", md: "start" },
              marginBottom: upMd ? 0 : 2,
            }}
          >
            {title}
          </Typography>
        )}
        <Box
          sx={{
            width: { xs: 320, md: 450, lg: 550 },
            height: { xs: 200, md: 260, lg: 350 },
            borderRadius: 5,
            objectFit: "cover",
            zIndex: 100,
          }}
          component="img"
          src={imageSrc}
        />
        <Stack>
          {upMd && (
            <Typography
              sx={{
                fontFamily: "Poppins, Roboto",
                fontSize: { xs: 25, md: 30, lg: 30 },
                width: { md: 350, lg: 300 },
                marginTop: { xs: 2 },
                textAlign: { xs: "center", md: "start" },
                marginBottom: upMd ? 0 : 2,
              }}
            >
              {title}
            </Typography>
          )}
          <Typography
            sx={{
              fontFamily: "Poppins, Roboto",
              fontSize: { xs: 12, md: 14, lg: 18 },
              opacity: 0.8,
              textAlign: { xs: "center", md: "start" },
              width: { xs: 365, md: "80%", lg: 450 },
              marginTop: { xs: 2 },
            }}
          >
            {desc}
          </Typography>
          <Button
            extraStyles={{
              width: 140,
              marginX: { xs: "auto", md: 0 },
              color: "black",
              cursor: "pointer",
              fontFamily: "Poppins, Roboto",
              marginTop: 2,
              fontSize: { xs: 10, md: 13 },
              borderColor: "#0364D9",
            }}
            onClick={onClick}
            variant="outlined"
            label={buttonLabel ? buttonLabel : "Get in Touch"}
          />
        </Stack>
      </Stack>
    </Grow>
  );
};

export default IdentityItem;
