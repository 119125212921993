import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Reason, data } from "./data";

type State = {
  data: Array<Reason>;
  activeItem: Reason;
};

const initialState: State = {
  data,
  activeItem: data[0],
};

const WhyUsSlice = createSlice({
  name: "why_us",
  initialState,
  reducers: {
    setActiveItem: (state: State, { payload }: PayloadAction<number>) => {
      const newItem = data[payload];
      state.activeItem.desc = newItem.desc;
      state.activeItem.title = newItem.title;
      state.activeItem.subTitle = newItem.subTitle;
      state.activeItem.id = newItem.id;
      return state;
    },
  },
});

export default WhyUsSlice.reducer;
export const WhyUsSliceActions = WhyUsSlice.actions;
