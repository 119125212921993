import { FC } from "react";
import { Box, Grow, Stack, Typography } from "@mui/material";
import styles from "./styles";
import Spacing from "components/spacing";
import ContactSection from "pages/home/contact_section";
import startups from "assets/startups.png";

const Startups: FC = () => {
  return (
    <>
      <Box sx={styles.container}>
        <Grow in timeout={900}>
          <Box
            component="img"
            src={startups}
            sx={{
              marginTop: { xs: 1, md: 0 },
              borderRadius: { xs: 3, md: 5, lg: 0 },
              zIndex: 1,
              objectFit: "cover",
              aspectRatio: "16 / 9",
              width: { xs: 350, md: "auto" },
            }}
          />
        </Grow>
        <Grow in timeout={900}>
          <Typography
            marginTop={{ xs: 2, md: 5 }}
            fontSize={{ md: 50, fontFamily: "Poppins, Roboto", xs: 35 }}
          >
            Startups
          </Typography>
        </Grow>
        <Grow in timeout={900}>
          <Box
            sx={{
              maxWidth: "90%",
              mx: "auto",
            }}
          >
            <Typography
              sx={{
                fontSize: { lg: 20, xs: 15 },
                width: { lg: "80%", xs: "100%" },
                fontFamily: "Poppins, Roboto",
                marginX: "auto",
                opacity: 0.7,
                marginTop: 3,
              }}
            >
              At our core, we are driven by a strong commitment to empower and
              support startup founders on their journey to success. Our
              dedication extends beyond mere mentorship to provide valuable
              advice and guidance to startups, ensuring they have the tools and
              knowledge to navigate the complexities of the business world,
              ultimately leading to their growth and prosperity.
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: 20, xs: 15 },
                fontFamily: "Poppins, Roboto",
                width: { lg: "80%", xs: "100%" },
                marginX: "auto",
                opacity: 0.7,
                marginTop: 5,
              }}
            >
              Furthermore, we actively engage in early-stage product
              investments, where we have a keen eye for identifying promising
              ventures with significant market potential. By investing in and
              supporting these innovative products, we contribute to their
              growth and the broader entrepreneurial ecosystem, fostering a
              landscape where innovation and value creation thrive.
            </Typography>
          </Box>
        </Grow>
        <Spacing reverse spacingY={5} />
      </Box>
      <Stack marginX="auto" width="85%" direction="column">
        <Typography
          sx={{
            marginBottom: 3,
            marginInlineStart: { lg: 12 },
            fontSize: 45,
          }}
        >
          Get in <span style={{ color: "#0364D9" }}>Touch</span>
        </Typography>
        <ContactSection />
      </Stack>
    </>
  );
};

export default Startups;
