import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  header: {
    fontSize: { md: 38, xs: 30 },
    fontFamily: "Poppins, Roboto",
    marginBottom: 5,
    textAlign: "center",
  },
};

export default styles;