import { FC } from "react";
import { Divider, Grid, Typography } from "@mui/material";

const InquiriesSection: FC = () => {
  return (
    <Grid
      item
      sx={{ textAlign: { xs: "center", md: "start" }, marginBottom: 5 }}
      xs={12}
      md={6}
    >
      <Typography
        sx={{
          marginBottom: 3,
          fontFamily: "Poppins, Roboto",
          fontSize: { xs: 30, md: 38, lg: 50 },
          whiteSpace: "nowrap",
        }}
      >
        Get in <span style={{ color: "#0364D9" }}>Touch</span>
      </Typography>
      <Typography sx={{ opacity: 0.6 }}>General Inquiries</Typography>
      <Typography
        sx={{
          mt: 0.5,
          mb: 3,
          fontFamily: "Poppins, Roboto",
          fontSize: { xs: 30 },
        }}
      >
        Info@crowddp.com
      </Typography>
      <Typography sx={{ opacity: 0.6, fontFamily: "Poppins, Roboto" }}>
        Careers
      </Typography>
      <Typography
        sx={{
          mt: 0.5,
          mb: 3,
          fontFamily: "Poppins, Roboto",
          fontSize: { xs: 30 },
        }}
      >
        Careers@crowddp.com
      </Typography>
      <Typography sx={{ opacity: 0.6, fontFamily: "Poppins, Roboto" }}>
        Headquarters
      </Typography>
      <Divider orientation="horizontal" sx={{ maxWidth: "80%" }} />
      <Typography
        sx={{ mt: 1, mb: 3, fontFamily: "Poppins, Roboto", opacity: 0.6 }}
      >
        Clover bay tower, office 1009, Downtown Dubai, United Arab Emirates
      </Typography>
    </Grid>
  );
};

export default InquiriesSection;
