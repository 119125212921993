import { FC } from "react";
import { Box, Grow, Stack, Typography } from "@mui/material";
import styles from "./styles";
import Spacing from "components/spacing";
import ContactSection from "pages/home/contact_section";
import careers from "assets/careers.png";

const Careers: FC = () => {
  return (
    <>
      <Box sx={styles.container}>
        <Grow in timeout={900}>
          <Box
            component="img"
            src={careers}
            sx={{
              marginTop: { xs: 1, md: 0 },
              borderRadius: { xs: 3, md: 5, lg: 0 },
              zIndex: 1,
              objectFit: "cover",
              aspectRatio: "16 / 9",
              width: { xs: 350, md: "auto" },
            }}
          />
        </Grow>
        <Grow in timeout={900}>
          <Typography
            marginTop={5}
            fontSize={{ lg: 50, fontFamily: "Poppins, Roboto", xs: 30 }}
          >
            Careers
          </Typography>
        </Grow>
        <Grow in timeout={900}>
          <Box
            sx={{
              maxWidth: "90%",
              mx: "auto",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: 16, lg: 21 },
                width: "80%",
                fontFamily: "Poppins, Roboto",
                marginX: "auto",
                opacity: 0.7,
                marginTop: 3,
              }}
            >
              As we continue to grow, we're offering exciting career
              opportunities that come with competitive benefits and a supportive
              work environment. If you're ready to be part of a dynamic team and
              make a difference apply now.
            </Typography>
          </Box>
        </Grow>
        <Spacing reverse spacingY={10} />
      </Box>
      <Stack marginX="auto" width="85%" direction="column">
        <Typography
          sx={{
            marginBottom: 3,
            fontFamily: "Poppins, Roboto",
            marginInlineStart: { lg: 12 },
            fontSize: 50,
          }}
        >
          Apply <span style={{ color: "#0364D9" }}>Now</span>
        </Typography>
        <ContactSection />
      </Stack>
    </>
  );
};

export default Careers;
