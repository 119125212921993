import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    justifyContent: "space-between",
    rowGap: {
      xs: 2,
      md: 2,
      lg: 4,
    },
  },
  wrapper: {
    display: "flex",
    padding: 4,
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "space-around",
    columnGap: 4,
    maxWidth: {
      lg: "80%",
    },
    mx: "auto",
  },
};

export default styles;
