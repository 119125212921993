import { FC, PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";
import { NavigationRoutes } from "./routes";

const RootNavigator: FC<PropsWithChildren<NonNullable<unknown>>> = ({
  children,
}) => {
  return (
    <BrowserRouter
      basename={NavigationRoutes.home}
      future={{ v7_startTransition: true }}
    >
      {children}
    </BrowserRouter>
  );
};

export default RootNavigator;
