import { FC } from "react";
import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import styles from "./styles";
import stats from "assets/dubai_statistics.png";
import courts from "assets/dubai_courts.png";
import s_logo from "assets/s_logo.png";
import police_acedamy from "assets/police_acedamy.png";
import idc from "assets/idc.png";
import police from "assets/police.png";

const Seperator = () => {
  return <Box sx={styles.seperator} />;
};

const MobileView = () => {
  return (
    <>
      <Box sx={styles.mobilePaperWrapper}>
        <Box
          component="img"
          sx={{ width: { xs: 100, lg: 100 }, objectFit: "contain" }}
          src={police}
        />
        <Seperator />
        <Box
          component="img"
          sx={{ objectFit: "contain", width: { xs: 100, lg: 100 } }}
          src={police_acedamy}
        />
        <Seperator />
        <Box
          component="img"
          sx={{ width: { xs: 100, lg: 100 }, objectFit: "contain" }}
          src={idc}
        />
      </Box>
      <Box sx={styles.mobilePaperWrapper}>
        <Box
          component="img"
          sx={{ width: { xs: 100, lg: 100 }, objectFit: "contain" }}
          src={courts}
        />
        <Seperator />
        <Box
          component="img"
          sx={{ width: { xs: 100, lg: 100 }, objectFit: "contain" }}
          src={stats}
        />
        <Seperator />
        <Box
          component="img"
          sx={{ width: { xs: 100, lg: 100 }, objectFit: "contain" }}
          src={s_logo}
        />
      </Box>
    </>
  );
};

const ClientsSection: FC = () => {
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={styles.container}>
      <Typography
        sx={{
          fontSize: { xs: 30, md: 38 },
          fontFamily: "Poppins, Roboto",
          mb: { xs: 5 },
          textAlign: "center",
        }}
      >
        Star Clients
      </Typography>
      {lgScreen ? (
        <Paper sx={styles.paperWrapper}>
          <Box component="img" src={courts} />
          <Seperator />
          <Box component="img" src={stats} />
          <Seperator />
          <Box component="img" src={s_logo} />
          <Seperator />
          <Box component="img" src={police_acedamy} />
          <Seperator />
          <Box component="img" src={idc} />
          <Seperator />
          <Box component="img" src={police} />
        </Paper>
      ) : (
        <MobileView />
      )}
    </Box>
  );
};

export default ClientsSection;
