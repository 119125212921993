import { FC, useMemo } from "react";
import { ButtonProps, Button as MuiButton, SxProps } from "@mui/material";
import styles from "./styles";

type Props = {
  label: string;
  onClick?: () => void;
  variant?: "outlined" | "contained";
  extraStyles?: SxProps<ButtonProps>;
};

const Button: FC<Props> = ({
  label,
  extraStyles,
  onClick,
  variant = "contained",
}) => {
  const style = useMemo(() => {
    return { ...styles.container, ...extraStyles };
  }, [extraStyles]);

  return (
    <MuiButton
      variant={variant}
      sx={style}
      name="Custom Button"
      onClick={onClick}
    >
      {label}
    </MuiButton>
  );
};

export default Button;
