import { FC } from "react";
import { Box, Grow, Stack, Typography } from "@mui/material";
import styles from "./styles";
import hero from "assets/hero_image.png";
import hero_svg from "assets/hero_intro.png";
import hero_bg from "assets/hero_bg.png";

const HeroSection: FC = () => {
  return (
    <>
      <Stack
        rowGap={5}
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ md: "space-around" }}
        alignItems={{ sm: "center", lg: "flex-start" }}
        sx={{
          ...styles.container,
          position: "relative",
          background: `url(${hero_bg})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box sx={{ width: { md: "30%", lg: "30%" } }}>
          <Grow in timeout={1000}>
            <Typography sx={styles.header}>
              WE CREATE <br />
              THE{" "}
              <span style={{ color: "#0364D9", fontWeight: "400" }}>NEXT</span>
            </Typography>
          </Grow>
          <Grow in timeout={1000}>
            <Typography sx={styles.subHeader}>
              For Organization looking to drive change, we deliver your
              technology vision.
            </Typography>
          </Grow>
        </Box>
        <Grow in timeout={1000}>
          <Box component="img" sx={styles.img} src={hero} />
        </Grow>
      </Stack>
      <Grow in timeout={1000}>
        <Box
          sx={{
            position: "absolute",
            zIndex: -100,
            top: { xs: 370, md: 200, lg: 450 },
          }}
          component="img"
          src={hero_svg}
        />
      </Grow>
    </>
  );
};

export default HeroSection;
