import { FC } from "react";
import { Box } from "@mui/material";
import styles from "./styles";
import HeroSection from "./hero_section";
import WhyUsSection from "./why_us";
import PartnersSection from "./partners";
import ExpertiseSection from "./expertise_section";
import ClientsSection from "./clients_section";
import OurIdentity from "components/our_identity";
import Spacing from "components/spacing";
import InquiryForm from "components/inquiry_form";

const Home: FC = () => {
  return (
    <Box sx={styles.container}>
      <HeroSection />
      {/* TODO: HOC */}
      <WhyUsSection />
      <PartnersSection />
      <Spacing spacingY={5} />
      <ExpertiseSection />
      <Spacing spacingY={5} />
      <OurIdentity />
      <ClientsSection />
      <InquiryForm />
    </Box>
  );
};

export default Home;
