import { FC } from "react";
import {
  Box,
  Paper,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

type Props = {
  icon: string;
  title?: string;
  desc?: string;
  subTitle?: string;
  height?: number;
  onClick?: () => void;
  onHover?: () => void;
};

const MobileComponent: FC<Props> = ({
  title,
  subTitle,
  onClick,
  desc,
  icon,
}) => {
  return (
    <Paper
      onClick={onClick}
      sx={{
        padding: 1.5,
        boxShadow: 3,
        borderRadius: 3,
        background:
          "linear-gradient(180deg, #F7F7F7 0%, rgba(247, 247, 247, 0) 100%)",
        cursor: "pointer",
      }}
    >
      <Stack direction="row">
        <Stack
          direction="column"
          width="100%"
          rowGap={0.8}
          justifyContent="flex-end"
        >
          <Typography
            sx={{
              width: "100%",
              fontFamily: "Poppins, Roboto",
              fontSize: { lg: 23, xs: 18 },
            }}
          >
            {title}
          </Typography>
          {subTitle && (
            <Typography
              sx={{
                fontSize: { lg: 15, xs: 12 },
                width: { xs: "90%", lg: "100%" },
                opacity: 0.7,
                fontFamily: "Poppins, Roboto",
              }}
            >
              {subTitle}
            </Typography>
          )}
          <Typography
            sx={{
              fontSize: { lg: 15, xs: 12 },
              width: { xs: "90%", lg: "100%" },
              opacity: 0.6,
              fontFamily: "Poppins, Roboto",
            }}
          >
            {desc}
          </Typography>
        </Stack>
        <Box
          component="img"
          sx={{ width: 30, objectFit: "contain" }}
          src={icon}
        />
      </Stack>
    </Paper>
  );
};

const IconCard: FC<Props> = ({
  icon,
  title,
  subTitle,
  onClick,
  desc,
  height,
}) => {
  const upMd = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  return (
    <>
      {upMd ? (
        <Paper
          onMouseEnter={onClick}
          sx={{
            padding: 2,
            height: height ? height : 250,
            boxShadow: 3,
            borderRadius: 3,
            background:
              "linear-gradient(180deg, #F7F7F7 0%, rgba(247, 247, 247, 0) 100%)",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row" justifyContent="flex-end">
            <Box component="img" src={icon} />
          </Stack>
          <Stack direction="column" justifyContent="flex-end">
            <Typography
              sx={{
                width: "80%",
                fontFamily: "Poppins, Roboto",
                fontSize: 23,
              }}
            >
              {title}
            </Typography>
            {subTitle && (
              <Typography
                sx={{
                  fontSize: { lg: 15, xs: 12 },
                  width: { xs: "90%", lg: "100%" },
                  opacity: 0.8,
                  fontFamily: "Poppins, Roboto",
                }}
              >
                {subTitle}
              </Typography>
            )}
            <Typography
              sx={{ fontSize: 15, fontFamily: "Poppins, Roboto", opacity: 0.6 }}
            >
              {desc}
            </Typography>
          </Stack>
        </Paper>
      ) : (
        <MobileComponent
          onClick={onClick}
          title={title}
          desc={desc}
          subTitle={subTitle}
          icon={icon}
        />
      )}
    </>
  );
};

export default IconCard;
