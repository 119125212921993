import { FC } from "react";
import { Grid } from "@mui/material";
import styles from "./styles";
import InquiriesSection from "components/inquiries_section";
import ContactSection from "pages/home/contact_section";

const InquiryForm: FC = () => {
  return (
    <Grid width="100%" container sx={styles.container}>
      <Grid item md={5.6} lg={5.5}>
        <InquiriesSection />
      </Grid>
      <Grid item md={6.4} lg={6.5}>
        <ContactSection />
      </Grid>
    </Grid>
  );
};

export default InquiryForm;
