import { FC } from "react";
import {
  Box,
  Button,
  Divider,
  Grow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import styles from "./styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import { NavigationRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import logo from "assets/logo.svg";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { globalSliceActions } from "store/global_slice";

const AppBar: FC = () => {
  const dispatch = useAppDispatch();

  const appBarState = useAppSelector((state) => state.global.appBarState);

  const theme = useTheme();
  const upMed = useMediaQuery(theme.breakpoints.up("md"));

  const navigate = useNavigate();

  const toggleMenu = () => {
    dispatch(globalSliceActions.toggleAppBar());
  };

  const handleNavigate = (route: NavigationRoutes) => {
    navigate(route);
    toggleMenu();
  };

  const MenuItems = () => {
    return (
      <Grow in timeout={600}>
        <Box sx={styles.menu}>
          <Box
            sx={styles.cursor}
            onClick={() => handleNavigate(NavigationRoutes.home)}
          >
            <Typography
              sx={{
                fontFamily: "Poppins, Roboto",
              }}
            >
              Home
            </Typography>
          </Box>
          <Box
            sx={styles.cursor}
            onClick={() => handleNavigate(NavigationRoutes.expertise)}
          >
            <Typography
              sx={{
                fontFamily: "Poppins, Roboto",
              }}
            >
              Expertise
            </Typography>
          </Box>
          <Box
            sx={styles.cursor}
            onClick={() => handleNavigate(NavigationRoutes.startups)}
          >
            <Typography
              sx={{
                fontFamily: "Poppins, Roboto",
              }}
            >
              Startups
            </Typography>
          </Box>
          <Box
            sx={styles.cursor}
            onClick={() => handleNavigate(NavigationRoutes.careers)}
          >
            <Typography
              sx={{
                fontFamily: "Poppins, Roboto",
              }}
            >
              Careers
            </Typography>
          </Box>
          <Box component="a" href="#contact_form">
            <Button
              sx={{
                whiteSpace: "nowrap",
                color: "#0364D9",
                borderColor: "#0364D9",
              }}
              variant="outlined"
            >
              Get in Touch
            </Button>
          </Box>
        </Box>
      </Grow>
    );
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Grow in timeout={900}>
            <Box
              sx={styles.cursor}
              onClick={() => handleNavigate(NavigationRoutes.home)}
            >
              <Box component="img" sx={{ width: { xs: 85 } }} src={logo} />
            </Box>
          </Grow>
          {!upMed && <MenuIcon onClick={() => toggleMenu()} />}
        </Box>
        {upMed && <MenuItems />}
        {!upMed && appBarState && <MenuItems />}
      </Box>
      <Divider sx={{ width: "100%" }} />
    </>
  );
};

export default AppBar;
