import { Box } from "@mui/material";
import { ComponentType, useEffect } from "react";
import AppBar from "components/app_bar";
import Footersection from "components/footer";

function withScreenHeaders(Component: ComponentType) {
  return () => {
    useEffect(() => {
      window.scrollTo({ behavior: "smooth", top: 0 });
      window.scrollX = 0;
      window.scrollY = 0;
    }, []);

    return (
      <Box>
        <AppBar />
        <Component />
        <Footersection />
      </Box>
    );
  };
}

export default withScreenHeaders;
