import { FC } from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import Spacing from "components/spacing";
import developer_1 from "assets/developers.png";
import hiring from "assets/hiring.png";
import right_sep from "assets/right_section_bg.png";
import IdentityItem from "components/identity_item";
import { useAppDispatch } from "store/hooks";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { IdentitySliceActions } from "store/identity_slice";

const OurIdentity: FC = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleNavigate = (index: number) => {
    dispatch(IdentitySliceActions.setActiveItem(index));
    navigate(NavigationRoutes.identity);
  };

  return (
    <Stack
      sx={{
        position: "relative",
        paddingBlock: 10,
        background:
          "linear-gradient(180deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%)",
      }}
      rowGap={20}
    >
      <IdentityItem
        imageSrc={developer_1}
        title="We Are Developers, investors and advisors"
        buttonLabel="Learn More"
        onClick={() => handleNavigate(0)}
        desc="We are dedicated to empowering startup founders, providing valuable advice and mentorship to startups to ensure their success and growth. Additionally, we actively invest in early-stage products, recognizing their market potential and value."
      />
      {md && <Spacing spacingY={1} />}
      <Box
        sx={{ position: "absolute", top: 380, right: "30%" }}
        component="img"
        src={right_sep}
      />
      <IdentityItem
        imageSrc={hiring}
        title="We are hiring"
        onClick={() => handleNavigate(1)}
        buttonLabel="Learn More"
        desc="As we continue to grow, we're offering exciting career opportunities that come with competitive benefits and a supportive work environment. If you're ready to be part of a dynamic team and make a difference apply now."
      />
    </Stack>
  );
};

export default OurIdentity;
