import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: {
      xs: "100%",
      lg: "80%",
    },
    marginX: "auto",
    paddingBottom: 15,
    paddingTop: 2.5,
  },
  header: {
    fontFamily: "Poppins, Roboto",
    fontSize: {
      xs: 40,
      md: 45,
      lg: 55,
    },
    marginBottom: 5,
    marginInlineStart: { xs: 2, md: 12, lg: 5, xl: 30 },
  },
  highlightedText: {
    color: "blue",
    fontSize: {
      xs: 35,
      md: 45,
      lg: 55,
    },
    marginInlineStart: 1,
  },
  subHeader: {
    fontSize: {
      xs: 22,
      md: 26,
      lg: 34,
    },
    marginTop: 2,
    width: {
      md: "70%",
    },
  },
};

export default styles;
