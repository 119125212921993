import { FC, PropsWithChildren } from "react";
import { Box, Grow, Stack, Typography } from "@mui/material";
import Button from "components/custom_button";
import { useAppDispatch } from "store/hooks";
import { globalSliceActions } from "store/global_slice";

type Props = {
  imageSrc: string;
  title: string;
  desc: string;
  reverse?: boolean;
  buttonLabel?: string;
  onClick?: () => void;
  sectionId?: string;
};

const MediaSection: FC<PropsWithChildren<Props>> = ({
  imageSrc,
  title,
  desc,
  sectionId,
  reverse,
  buttonLabel,
}) => {
  const dispatch = useAppDispatch();

  const handleScrollBottom = () => {
    dispatch(globalSliceActions.toggleAppBar());
    const element = document.getElementById("contact_form");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Grow id={sectionId} in timeout={600}>
      <Stack
        id={sectionId}
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "center" }}
        justifyContent={{ md: "center" }}
        columnGap={{ md: 5 }}
        sx={{ width: { xs: "80%" }, marginX: { xs: "auto" } }}
      >
        <Box
          sx={{
            width: { xs: 350, md: 440, lg: 550 },
            height: { xs: 250, md: 300, lg: 350 },
            order: { md: reverse ? 1 : 0 },
            borderRadius: 5,
            objectFit: "cover",
          }}
          component="img"
          src={imageSrc}
        />
        <Stack
          sx={{
            textAlign: { xs: "center", md: "start" },
            order: { md: reverse ? 0 : 1 },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins, Roboto",
              fontSize: { xs: 15, md: 35, lg: 30 },
              width: { md: 250, lg: 280 },
              marginTop: { xs: 2 },
              lineHeight: 1.1,
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins, Roboto",
              fontSize: { xs: 12, lg: 18 },
              opacity: 0.8,
              width: { xs: 365, md: "80%", lg: 450 },
              marginTop: { xs: 2 },
            }}
          >
            {desc}
          </Typography>
          <Button
            extraStyles={{
              width: 140,
              marginX: { xs: "auto", md: 0 },
              color: "black",
              fontFamily: "Poppins, Roboto",
              marginTop: 2,
            }}
            onClick={() => handleScrollBottom()}
            variant="outlined"
            label={buttonLabel ? buttonLabel : "Get in Touch"}
          />
        </Stack>
      </Stack>
    </Grow>
  );
};

export default MediaSection;
