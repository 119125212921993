import { FC } from "react";
import { Box, Paper, Typography } from "@mui/material";
import styles from "./styles";
import logo from "assets/logo_dark.svg";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";

const Footer: FC = () => {
  const navigate = useNavigate();

  const handleNavigate = (path: NavigationRoutes) => {
    navigate(path);
  };

  return (
    <Paper sx={styles.container}>
      <Box sx={styles.header}>
        <Box component="img" sx={{ width: { xs: 85 } }} src={logo} />
      </Box>
      <Box sx={styles.menu}>
        <Typography
          onClick={() => handleNavigate(NavigationRoutes.home)}
          sx={{
            cursor: "pointer",
            fontFamily: "Poppins, Roboto",
          }}
        >
          Home
        </Typography>
        <Typography
          onClick={() => handleNavigate(NavigationRoutes.expertise)}
          sx={{
            cursor: "pointer",
            fontFamily: "Poppins, Roboto",
          }}
        >
          Expertise
        </Typography>
        <Typography
          onClick={() => handleNavigate(NavigationRoutes.startups)}
          sx={{
            cursor: "pointer",
            fontFamily: "Poppins, Roboto",
          }}
        >
          Startups
        </Typography>
        <Typography
          onClick={() => handleNavigate(NavigationRoutes.careers)}
          sx={{
            cursor: "pointer",
            fontFamily: "Poppins, Roboto",
          }}
        >
          Careers
        </Typography>
      </Box>
      <Typography
        sx={{
          opacity: 0.6,
          fontSize: { xs: 11 },
          fontFamily: "Poppins, Roboto",
          whiteSpace: "nowrap",
        }}
      >
        ©2023 Crowd Digital Portal, All rights reserved.
      </Typography>
    </Paper>
  );
};

export default Footer;
