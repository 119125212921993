import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "100%",
    marginX: "auto",
  },
  header: { fontSize: 35 },
  highlightedText: { color: "blue", fontSize: 35, marginInlineStart: 0.5 },
  subHeader: { fontSize: 22, marginTop: 2 },
};

export default styles;