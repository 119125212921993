import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    backgroundColor: "#F5F5F5",
    display: "flex",
    paddingBlock: 5,
    flexDirection: {
      xs: "column",
      md: "row",
    },
    paddingInline: 5,
    justifyContent: "space-between",
    alignItems: "center",
    rowGap: { xs: 5, md: 0 },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: {
      xs: "center",
      md: "flex-start",
    },
    alignItems: "center",
  },
  menu: {
    display: "flex",
    flexDirection: {
      xs: "row",
    },
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: {
      xs: 3,
      md: 5,
      lg: 3,
    },
  },
};

export default styles;
